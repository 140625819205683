.homepage {
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  .navbar {
    width: 100%;
    height: 4rem;
    padding: 0 8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .hamMenu-home {
      display: none;
      position: absolute;
      left: 1rem;
      height: 100%;

      img {
        width: 20px;
      }
    }

    .logo {
      display: flex;
    }

    .menu-items {
      display: flex;
      gap: 2rem;
      align-items: center;
      font-size: 0.9rem;

      p {
        margin: 0;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .btns-div {
      display: flex;
      gap: 1rem;

      .gs-Btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
        padding: 0.7rem 1.5rem;
        font-size: 0.8rem;
      }

      .business-btn {
        color: white;
        font-weight: 500;
        border-radius: 35px;
        background: #100f0d;
        padding: 0.5rem 1.5rem;
      }

      div {
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
    a{
      text-decoration: none;
      color:black
    }
  }

  .color-box {
    width: 100%;
    height: 1rem;
    background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
  }

  .homepage-content {
    width: 100%;
    height: calc(100% - 5rem);
    overflow-y: scroll;

    .cover-Img {
      width: 100%;
      position: relative;

      img {
        width: 100%;
        height:80vh;
        z-index: 1;
        min-height: 100%;
      }

      .background-tint {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.19);
        z-index: 2;
        bottom: 1%;
      }

      .mid-text {
        align-items: center;
        color: #fff;
        display: flex;
        font-size: 3.0rem;
        font-weight: 700;
        inset: 0;
        bottom: 8rem;
        justify-content: center;
        position: absolute;
        z-index: 3;
      }

      .background-tint1 {
        background: rgba(0, 0, 0, 0.65);
        width: 100%;
        z-index: 3;
        bottom: 0.5%;
        left: 0;
        height: 5rem;
        position: absolute;
      }

      .input-box-container {
        position: absolute;
        z-index: 4;
        height: 5rem;
        bottom: 0.5%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .input-box1 {
          z-index: 4;
          height: 3rem;
          display: flex;

          input {
            border: none;
            border-radius: 45px;
            width: 22rem;
            padding: 0 2rem 0 1rem;
            font-weight: 500;
          }
        }

        .input-box2 {
          z-index: 4;
          height: 3rem;
          display: flex;

          input {
            border: none;
            border-radius: 45px;
            width: 12rem;
            padding: 0 2rem 0 1rem;
            font-weight: 500;
          }
        }

        .createPath-btn {
          z-index: 4;
          height: 3rem;
          display: flex;
          color: white;
          background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
          border-radius: 45px;
          padding: 0 2rem;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          cursor: pointer;
          transition: all ease 0.2s;
          font-size: 0.9rem;

          &:hover {
            transform: scale(1.05);
          }
        }
      }
    }

    .cover-Img-mobile {
      width: 100%;
      position: relative;
      display: none;

      img {
        width: 100%;
        z-index: 1;
        min-height: 100%;
      }

      .background-tint-mobile {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.19);
        z-index: 2;
      }

      .mid-text-mobile {
        align-items: center;
        color: #fff;
        display: flex;
        font-size: 1rem;
        font-weight: 700;
        inset: 0;
        top: -7rem;
        justify-content: center;
        position: absolute;
        z-index: 3;
        width: 100%;
        text-align: center;
      }

      .background-tint1-mobile {
        background: rgba(0, 0, 0, 0.65);
        width: 100%;
        z-index: 3;
        left: 0;
        height: 5rem;
        position: absolute;
        bottom: 0;
      }

      .input-box-container-mobile {
        position: absolute;
        z-index: 4;
        height: 7rem;
        bottom: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .createPath-btn-mobile {
          z-index: 4;
          height: 2.5rem;
          display: flex;
          color: white;
          background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
          border-radius: 45px;
          padding: 0 2rem;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          transition: all ease 0.2s;
          font-size: 0.9rem;
          position: absolute;
          top:4.3rem;
        }
      }
    }

    .hiw-container {
      width: 100%;
      // padding: 4rem 8rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .hiw-text {
        font-size: 1.5rem;
        font-weight: 500;
      }

      .hiw-options {
        display: flex;
        width: 100%;
        position: relative;
        justify-content: space-between;

        .each-hiw-option {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          z-index: 2;
          background: white;
          cursor: pointer;
          transition: all ease 0.2s;

          .img-border {
            border: 1px solid #e7e7e7;
            border-radius: 50%;
            padding: 2rem;
          }

          .each-hiw-option-name {
            font-size: 0.9rem;
            font-weight: 500;
            text-align: center;
          }

          &:hover {
            .img-border {
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }

            .each-hiw-option-name {
              transform: scale(1.05);
            }
          }
        }

        .centre-line {
          position: absolute;
          width: 100%;
          border: 0.5px solid #e5e5e5;
          top: 42%;
          z-index: 1;
        }
      }
    }
    .pathImage{
      width: 90%;
      height: 90%;
    }
    .points{
      list-style-type: none;
    }
    .tickImage{
      width:30px;
      height: 30px;
    }
    .number{
      width: auto;
      height:8vh;
      align-items: center;
      padding: 10px;
      border-radius: 50%;
      //background-color: lightcoral;
      img{
        width: 25px;
        height: 20px;
        margin-top: 5px;
      }
    }
    .cards{
      // background-color: lightblue;
      border-radius: 40px 10px 10px 40px;
    }
    .card-text{
      opacity: 0;
      animation: fadeIn 1s ease-out forwards;
    }
    .hide-height {
      height: 0;
      overflow: hidden;
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
    .footer{
      background: linear-gradient(89deg, #47b4d5 0%, #29449d 100%);
      margin-bottom: -15px;
    }
  }
}

@media screen and (max-width: 800px) {
  .homepage {
    .navbar {
      .hamMenu-home {
        display: flex;
      }
      .menu-items {
        display: none;
      }
      .btns-div {
        display: none;
      }
    }
    .color-box {
      height: 0.5rem;
    }
    .homepage-content {
      height: calc(100% - 4.5rem);
      .cover-Img {
        display: none;
      }
      .cover-Img-mobile {
        display: flex;
      }
      .hiw-container {
        padding: 4rem 0rem;
        align-items: center;
        .hiw-options {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2rem;
          .each-hiw-option {
            width: 50%;
            justify-content: center;
            align-items: center;
            .img-border {
              width: 150px;
              height: 150px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
            }
          }
          .centre-line {
            display: none;
          }
        }
      }
    }
  }
}
